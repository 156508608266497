<template>
  <div class="">
    <PreLoader v-if="isLoading" />

    <FolderMediaForm
      v-if="!isLoading"
      :folderMediaData="employeesFoldersMedias.employeesFoldersMedia"
      :defaultImg="
        BASE_URL + employeesFoldersMedias.employeesFoldersMedia.folderMediaPath
      "
      v-on:addOrUpdateEmployeesFoldersMedia="addOrUpdateEmployeesFoldersMedia"
      :submitName="$t('edit')"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import { STATUS, BASE_URL } from "@/utils/constants";
import FolderMediaForm from "@/components/employees/employeesFoldersMedias/FolderMediaForm.vue";
import EmployeesFoldersMedias from "@/models/employees/employeesFoldersMedias/EmployeesFoldersMedias";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "FolderMediaEdit",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    FolderMediaForm,
  },
  props: ["folderMediaToken"],
  data() {
    return {
      isLoading: false,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      BASE_URL: BASE_URL,
      employeesFoldersMedias: new EmployeesFoldersMedias(),
    };
  },
  methods: {
    async getEmployeesFoldersMediaDetails() {
      this.isLoading = true;
      try {
        this.employeesFoldersMedias.employeesFoldersMedia.folderMediaToken =
          this.folderMediaToken;
        let response =
          await this.employeesFoldersMedias.employeesFoldersMedia.getEmployeesFoldersMediaDetails(
            this.language,
            this.userAuthorizeToken
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.employeesFoldersMedias.employeesFoldersMedia.fillData(
            response.data.employeesFoldersMediaData
          );
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
          this.$router.push({ name: "EmployeesFoldersMedias" }).catch(() => {});
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.$router.push({ name: "EmployeesFoldersMedias" }).catch(() => {});
      }
      this.isLoading = false;
    },
    async addOrUpdateEmployeesFoldersMedia() {
      this.isLoading = true;
      try {
        let response =
          await this.employeesFoldersMedias.employeesFoldersMedia.addOrUpdateEmployeesFoldersMedia(
            this.language,
            this.userAuthorizeToken
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$router.push({
            name: "EmployeesFoldersMedias",
            params: {
              employeeToken:
                this.employeesFoldersMedias.employeesFoldersMedia.employeeToken,
              folderMediaToken:
                this.employeesFoldersMedias.employeesFoldersMedia
                  .employeeFolderToken,
            },
          });
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getEmployeesFoldersMediaDetails();
  },
};
</script>
